<template>
    <div class="title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex">
        <span>
            <img :src="$config('app.appLogoIcon')" width="35" height="34" />
        </span>
        <span class="ml-2 hidden-sm-and-down">
            <img
                :src="$config('app.appLogoText')"
                class="mt-1"
                height="35"
                width="167"
            />
        </span>
    </div>
</template>
<script>
export default {
    name: "app-logo",
};
</script>

<style scoped>
</style>