var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex"
    },
    [
      _c("span", [
        _c("img", {
          attrs: {
            src: _vm.$config("app.appLogoIcon"),
            width: "35",
            height: "34"
          }
        })
      ]),
      _c("span", { staticClass: "ml-2 hidden-sm-and-down" }, [
        _c("img", {
          staticClass: "mt-1",
          attrs: {
            src: _vm.$config("app.appLogoText"),
            height: "35",
            width: "167"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }